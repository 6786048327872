import { makeRootDoc } from "./rootDoc.js";
export function makeFormRequest({ Form, SubmitBtn }) {
    const RootDoc = makeRootDoc();
    return Object.freeze({
        init,
        afterResponse
    });
    function init(preservePointerEvents) {
        _preventSubmit();
        if (!preservePointerEvents)
            _removePointerEvents();
        SubmitBtn.setLoading();
    }
    function afterResponse(reset) {
        _enableSubmit();
        SubmitBtn.reset();
        if (reset)
            Form?.reset();
    }
    function _preventSubmit() {
        document.addEventListener("keydown", _onPreventFormSubmit);
    }
    function _removePointerEvents() {
        RootDoc.classList.add("no-pointers");
    }
    function _enableSubmit() {
        document.removeEventListener("keydown", _onPreventFormSubmit);
        RootDoc.classList.remove("no-pointers");
    }
    function _onPreventFormSubmit(e) {
        if (e.code === "Enter")
            e.preventDefault(); //avoid submitting form
    }
}
